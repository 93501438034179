<template>
  <div>
    <section class="vh-100">
      <div class="container mt-5 h-90">
        <b-row class="text-center">
          <b-col>
            <img
              src="../assets/images/makeping-logo1.png"
              alt="MakePing"
              style="height: 8vh"
            />
          </b-col>
        </b-row>
        <b-row class="text-center mt-4 mb-4">
          <b-col>
            <h1 style="font-size: 50px; font-weight: 900; color: white">
              Upgrade <span style="color: #f7981e">Plan</span>
            </h1>
          </b-col>
        </b-row>
      </div>

      <div class="container h-50">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-12 col-md-8 col-lg-6 col-xl-5">
            <div class="card" style="border-radius: 1rem">
              <div class="card-body text-left">
                <button
                    class="btn btn-lg btn-block mb-2"
                    type="submit"
                    @click="upgrade_modal = true"
                  >
                    Upgrade
                  </button>
                <!-- <div v-if="otp_send == true">
                  <div class="form-outline mb-4">
                    <input
                      v-model="input.otp"
                      v-on:keyup.enter="VerifyOtp()"
                      type="text"
                      id="otp-2"
                      class="form-control form-control-lg"
                      placeholder="Please Enter OTP"
                      required
                    />
                  </div>
                  <button
                    class="btn btn-lg btn-block"
                    type="submit"
                    @click="VerifyOtp()"
                  >
                    Verify
                  </button>
                  <b-row class="mt-2">
                    <b-col class="text-center">
                      <span v-if="wait_30 == false"
                        >didn't receive code
                        <span class="link_txt" @click="SendOtp()"
                          >click here to resend.</span
                        >
                      </span>
                      <span v-else>Please wait {{ count }} seconds to resend OTP.</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="text-center">
                      <span class="text-danger">* Please check spam folder too.</span>
                    </b-col>
                  </b-row>
                </div> -->
                <!-- <div v-else>
                  <button class="btn btn-lg btn-block" @click="SendOtp()">
                    Send OTP
                  </button>
                </div> -->
                <!-- <hr class="my-4" /> -->
                <div class="form-outline text-center">
                <router-link style="color:#F7981E;" tag="a" to="/auth/login" ><h6>Go to Login Page</h6></router-link>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <b-modal id="modal-monitor" title="Plan Details" size="xl" class="theme-modal"
      v-model="upgrade_modal" no-close-on-backdrop hide-footer>
      <upgrade-component></upgrade-component>
    </b-modal>
  </div>
</template>

<script>
import UserController from "../../services/userController";
import Userauth from "../auth/js/index";
import UpgradeComponent from '../views/UpgradePlan.vue'
export default {
  name: "Verification",
  components: { UpgradeComponent },
  data() {
    return {
      input: { otp: "" },
      otp_send: false,
      wait_30: false,
      count: 30,
      timer: "",
      upgrade_modal: true
    };
  },
  mounted() {
    // this.SendOtp();
    window.addEventListener("popstate", this.handleBack);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.handleBack);
  },
  methods: {
    async VerifyOtp() {
      if (this.input.otp != "") {
        let response = await UserController.verifyOtp(this.input);
  
        if (response.result) {
          response = await UserController.acceptCall(this.input)

          if(response.message.isactive){
              Userauth.localLogin(response.message);
              this.$router.replace("/app/dashboard");
          }

          this.$toasted.show("Successfully Registered and Logged In", {
            theme: "outline",
            position: "bottom-center",
            type: "success",
            duration: 3000,
          });
          
        } else {
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
        }
      } else {
        this.$toasted.show("Please enter OTP", {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
      }
    },
    async SendOtp() {
      let response = await UserController.sendOtp();
      if (response.result) {
        this.wait_30 = true;
        this.$toasted.show("Verification code sent. Please check your email.", {
          theme: "outline",
          position: "bottom-center",
          type: "success",
          duration: 4000,
        });
        this.otp_send = true;
        this.startTimer();
      } else {
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
      }
    },
    startTimer() {
      var timer = setInterval(() => {
        this.count--;
        if (this.count === 0) {
          clearInterval(timer);
          this.wait_30 = false;
          this.count = 30;
        }
      }, 1000);
    },
    handleBack() {
      const from = this.$route.query.from || "login"; // Default to login if no query param
      this.$router.replace(`/auth/${from}`);
    }
  },
};
</script>

<style>
body {
  background-image: url("../assets/images/login-bg.jpg") !important;
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: cover;
  height: 100%;
}
</style>

<style scoped>
.card {
  box-shadow: 0 0 15px 10px #000000 !important;
}
button {
  background-color: #f7981e;
  color: #fefeff;
}
</style>